import { css } from "@emotion/react";
import { useTheme } from "@ryerson/frontend.theme";
import { useApplication } from "@ryerson/frontend.application";
import {
	Input,
	EmailInput,
	PhoneInput,
	Option,
	Dropdown,
	IncrementDecrementInput,
} from "@ryerson/frontend.form-controls";
import { Icon } from "@ryerson/frontend.assets";
import { ContentSection, Media, Flex, FlexItem } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import React, { useState } from "react";
import { Breadcrumb } from "@ryerson/frontend.navigation";
import { Button } from "@ryerson/frontend.button";
import { states } from "@components/Shared/YourMetalExperts/YourMetalExperts";
import styled from "@emotion/styled";
import { APIUrl } from "@enums/apiUrl.enums";
import {
	sendGAEvent,
	GoogleAnalyticsEventName,
	GoogleAnalyticsEventActionNames,
} from "@ryerson/frontend.common";

export type FormContent = {
	requiredDirections: string;
	title: string;
	shipping: string;
	firstNameLabel: string;
	lastNameLabel: string;
	address1Label: string;
	address2Label: string;
	cityLabel: string;
	stateLabel: string;
	zipLabel: string;
	emailLabel: string;
	phoneLabel: string;
	quantityLabel: string;
	totalLabel: string;
	modalMessageSuccess: string;
	modalMessageError: string;
	buttonLabel: string;
	priceLabel: string;
	orderSummaryLabel: string;
};

type StocklistFormProps = {
	content: FormContent;
};

const Messager = styled.div`
	display: block;
	width: 100%;
	height: auto;
	margin-top: 20px;
`;

const MessagerIcon = styled.div`
	display: inline-block;
	width: 30px;
	height: 30px;
	margin-right: 10px;
	vertical-align: middle;
	box-sizing: border-box;
	border-radius: 30px;
	text-align: center;
	line-height: 38px;
`;

const MessagerText = styled.div`
	display: inline-block;
	vertical-align: middle;
	width: calc(100% - 40px);
	box-sizing: border-box;
`;

const BreadcrumbContainer = styled.div`
	margin-top: 32px;
	${(props: any) => {
		const { theme } = props;
		return css`
			@media only screen and (max-width: ${theme.breakpoints.lg}) {
				margin-top: 8px;
			}
		`;
	}}
`;

const TotalsContainer = styled.div`
	display: block;
	width: 100%;
	height: 40px;
	border-radius: 2px;
	padding: 10px 20px;
	box-sizing: border-box;
	margin-top: 5px;
`;

const Left = styled.div`
	display: inline-block;
	vertical-align: top;
	width: calc(100% - 400px);
	height: auto;
`;

const FormContainer = styled.div`
	display: block;
	width: 100%;
	max-width: 460px;
	height: auto;
`;

const Right = styled.div`
	display: inline-block;
	width: 390px;
	margin-left: 10px;
	vertical-align: top;
`;

const CoverImage = styled.div`
	display: block;
	width: 100%;
	height: 330px;
	margin-bottom: 10px;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	background-image: url(/images/stock-list/StockbookThumbnail.svg);
`;

const OrderSummary = styled.div`
	display: block;
	width: 100%;
	height: auto;
`;

const Spacer = styled.div`
	display: block;
	width: 100%;
	height: 44px;
`;

const OrderDetails = styled.div`
	display: inline-block;
	width: calc(100% - 95px);
	height: auto;
	vertical-align: top;
`;

const Divider = styled.hr`
	display: block;
	width: 100%;
	margin-top: 10px;
	margin-bottom: 25px;
	opacity: 0.1;
`;

const StocklistForm: React.FC<StocklistFormProps> = ({ content }) => {
	const {
		localization: { language },
		axiosInstance,
	} = useApplication();
	const { theme } = useTheme();

	const inputStyling = css`
		box-sizing: border-box;
		margin-bottom: 20px;
		@media only screen and (max-width: ${theme.breakpoints.lg}) {
			margin-bottom: 15px;
		}
	`;

	const pricePerEach = 9.95;

	const [submitted, setSubmitted] = useState<boolean>(false);
	const [firstName, setFirstName] = React.useState<string>("");
	const [lastName, setLastName] = React.useState<string>("");
	const [address1, setAddress1] = React.useState<string>("");
	const [address2, setAddress2] = React.useState<string>("");
	const [city, setCity] = React.useState<string>("");
	const [state, setState] = React.useState<string>("");
	const [zip, setZip] = React.useState<string>("");
	const [email, setEmail] = React.useState<string>("");
	const [phoneNumber, setPhoneNumber] = React.useState<string>("");
	const [quantity, setQuantity] = React.useState<string>("1");
	const [total, setTotal] = React.useState<number>(0.0);

	const [showAPIMessage, setShowAPIMessage] = React.useState<boolean>(false);
	const [apiMessage, setAPIMessage] = React.useState<string>("");
	const [apiStatus, setAPIStatus] = React.useState<string>("");

	React.useEffect(() => {
		if (!isNaN(Number(quantity)) && Number(quantity) > 0) {
			setTotal(pricePerEach * Number(quantity));
		} else {
			setTotal(0);
		}
	}, [quantity]);

	React.useEffect(() => {
		if (window) {
			const queryString = window.location.search;
			if (queryString) {
				const urlParams = new URLSearchParams(queryString);
				if (urlParams.get("qty")) {
					let qty = urlParams.get("qty");
					if (Number(qty) > 0) {
						setQuantity(String(qty));
					}
				}
			}
		}
	}, []);

	const onFormSubmit = () => {
		setShowAPIMessage(false);
		setSubmitted(true);
		if (
			firstName.length > 0 &&
			lastName.length > 0 &&
			email.length > 0 &&
			address1.length > 0 &&
			zip.length > 0 &&
			city.length > 0 &&
			state.length > 0 &&
			phoneNumber.length > 0 &&
			Number(quantity) > 0
		) {
			let saveObject: object = {
				quantity: Number(quantity),
				shipmentInfo: {
					firstName: firstName,
					lastName: lastName,
					email: email,
					telephone: String(phoneNumber),
					address1: address1,
					address2: address2,
					city: city,
					state: state,
					zip: String(zip),
					country: "US",
				},
			};
			sendGAEvent(GoogleAnalyticsEventName.STOCK_LIST, {
				action: GoogleAnalyticsEventActionNames.PURCHASE,
				value: quantity,
			});
			axiosInstance
				.post(APIUrl.STOCK_LIST_ORDER, saveObject)
				.then((response) => {
					if (response.status === 200) {
						let res = response.data;
						window.location.href = res.redirectUrl;
					} else {
						setAPIMessage(content.modalMessageError ?? "");
						setSubmitted(false);
						setShowAPIMessage(true);
						setAPIStatus("error");
						setTimeout(() => {
							setShowAPIMessage(false);
						}, 8000);
					}
				})
				.catch(() => {});
		} else {
			setAPIStatus("error");
			setAPIMessage("Please enter all required fields.");
			setShowAPIMessage(true);
			setTimeout(() => {
				setShowAPIMessage(false);
			}, 8000);
		}
	};

	let stateOptions: Option[] = [];
	for (let i = 0; i < states["United States"].length; i++) {
		stateOptions.push({
			value: states["United States"][i].id,
			display: states["United States"][i].label,
		});
	}

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection type="tertiary">
					<BreadcrumbContainer theme={theme}>
						<Breadcrumb type="tertiary" size="xs" />
					</BreadcrumbContainer>
					<Typography
						variant="h1"
						type="tertiary"
						css={css`
							margin-top: 16px;
							margin-bottom: 25px;
							padding-bottom: 5px;
							@media (max-width: ${theme.breakpoints.lg}) {
								margin-bottom: 22px;
							}
						`}
					>
						{content.title}
					</Typography>
					<Typography
						variant="p"
						type="tertiary"
						css={css`
							margin-bottom: 15px;
							padding-bottom: 13px;
							@media (max-width: ${theme.breakpoints.lg}) {
								margin-bottom: 12px;
							}
						`}
					>
						{content.shipping}
					</Typography>
					<Left>
						<ContentSection type="secondary" hPadding="42px">
							<FormContainer>
								<Typography
									variant="p"
									size="xs"
									color={theme.colors.primary.gray}
									italic={true}
								>
									{content.requiredDirections}
								</Typography>
								<Input
									label={content.firstNameLabel}
									value={firstName}
									onChange={(e) => {
										setFirstName(e.target.value);
									}}
									required={true}
									submitted={submitted}
									css={inputStyling}
									language={language}
								/>
								<Input
									label={content.lastNameLabel}
									value={lastName}
									onChange={(e) => {
										setLastName(e.target.value);
									}}
									required={true}
									submitted={submitted}
									css={inputStyling}
									language={language}
								/>
								<Input
									label={content.address1Label}
									value={address1}
									onChange={(e) => {
										setAddress1(e.target.value);
									}}
									required={true}
									submitted={submitted}
									css={inputStyling}
									language={language}
								/>
								<Input
									label={content.address2Label}
									value={address2}
									onChange={(e) => {
										setAddress2(e.target.value);
									}}
									required={false}
									submitted={submitted}
									css={inputStyling}
									language={language}
								/>
								<Flex direction="row" justifyContent="space-between">
									<FlexItem
										css={css`
											width: calc(45% - 10px);
										`}
									>
										<Input
											label={content.cityLabel}
											value={city}
											onChange={(e) => {
												setCity(e.target.value);
											}}
											required={true}
											submitted={submitted}
											css={inputStyling}
											language={language}
										/>
									</FlexItem>
									<FlexItem
										css={css`
											width: 10px;
										`}
									/>
									<FlexItem
										css={css`
											width: calc(35% - 10px);
										`}
									>
										<Dropdown
											language={language}
											options={stateOptions}
											label={content.stateLabel}
											value={state}
											submitted={submitted}
											required={true}
											onChange={(val) => {
												setState(val);
											}}
										/>
									</FlexItem>
									<FlexItem
										css={css`
											width: 10px;
										`}
									/>
									<FlexItem
										css={css`
											width: 20%;
										`}
									>
										<Input
											label={content.zipLabel}
											value={zip}
											onChange={(e) => {
												setZip(e.target.value);
											}}
											required={true}
											submitted={submitted}
											css={inputStyling}
											language={language}
										/>
									</FlexItem>
								</Flex>

								<EmailInput
									label={content.emailLabel}
									value={email}
									onChange={(e) => {
										setEmail(e.target.value);
									}}
									required={true}
									submitted={submitted}
									css={inputStyling}
									language={language}
								/>
								<PhoneInput
									label={content.phoneLabel}
									value={phoneNumber}
									onChange={(e) => {
										setPhoneNumber(e.target.value);
									}}
									required={true}
									submitted={submitted}
									css={inputStyling}
									language={language}
								/>
								<Flex direction="row" justifyContent="space-between">
									<FlexItem
										css={css`
											width: calc(35% - 5px);
										`}
									>
										<IncrementDecrementInput
											label={content.quantityLabel}
											required={true}
											onIncrement={() => {
												setQuantity(String(Number(quantity) + 1));
											}}
											onDecrement={() => {
												if (Number(quantity) > 1) {
													setQuantity(String(Number(quantity) - 1));
												}
											}}
											onChange={(e) => {
												setQuantity(e.target.value);
											}}
											value={quantity}
											language={language}
										/>
									</FlexItem>
									<FlexItem
										css={css`
											width: 10px;
										`}
									/>
									<FlexItem
										css={css`
											width: calc(65% - 5px);
										`}
									>
										<Typography
											variant="div"
											size="md"
											color={theme.colors.primary.header}
										>
											{content.totalLabel}
										</Typography>
										<TotalsContainer
											css={css`
												border: 1px solid ${theme.colors.primary.lightGray};
											`}
										>
											${total.toFixed(2)}
										</TotalsContainer>
									</FlexItem>
								</Flex>
								<Spacer />
								<Button
									size="lg"
									type="primary"
									label={content.buttonLabel}
									onClick={onFormSubmit}
								/>
								{showAPIMessage && (
									<Messager>
										<MessagerIcon
											css={css`
												background-color: ${apiStatus === "success"
													? theme.colors.secondary.green
													: theme.colors.secondary.red};
											`}
										>
											<Icon
												size="sm"
												color={theme.colors.primary.white}
												icon={apiStatus === "success" ? "check" : "minus"}
											/>
										</MessagerIcon>
										<MessagerText>
											<Typography variant="div" size="md" type="primary">
												{apiMessage}
											</Typography>
										</MessagerText>
									</Messager>
								)}
							</FormContainer>
						</ContentSection>
					</Left>
					<Right>
						<CoverImage />
						<OrderSummary>
							<ContentSection vPadding="20px" hPadding="20px" type="primary">
								<Typography variant="h3">{content.orderSummaryLabel}</Typography>
								<Divider
									css={css`
										color: ${theme.colors.primary.darkerGray};
									`}
								/>
								<OrderDetails>
									<Typography
										variant="div"
										size="md"
										color={theme.colors.primary.header}
									>
										{content.title}
									</Typography>
									<Flex
										direction="row"
										justifyContent="space-between"
										css={css`
											margin-top: 15px;
										`}
									>
										<FlexItem
											css={css`
												width: 50%;
											`}
										>
											<Typography variant="div" size="sm" type="primary">
												{content.quantityLabel}
											</Typography>
										</FlexItem>
										<FlexItem
											css={css`
												width: 50%;
											`}
										>
											<Typography
												variant="div"
												size="sm"
												weight="bold"
												color={theme.colors.primary.header}
												type="primary"
											>
												{quantity} pc(s)
											</Typography>
										</FlexItem>
									</Flex>
									<Flex
										direction="row"
										justifyContent="space-between"
										css={css`
											margin-top: 10px;
										`}
									>
										<FlexItem
											css={css`
												width: 50%;
											`}
										>
											<Typography variant="div" size="sm" type="primary">
												{content.priceLabel}
											</Typography>
										</FlexItem>
										<FlexItem
											css={css`
												width: 50%;
											`}
										>
											<Typography
												variant="div"
												size="sm"
												weight="bold"
												color={theme.colors.primary.header}
												type="primary"
											>
												USD ${total.toFixed(2)}
											</Typography>
										</FlexItem>
									</Flex>
								</OrderDetails>
								<Divider
									css={css`
										color: ${theme.colors.primary.darkerGray};
									`}
								/>
								<Flex direction="row" justifyContent="space-between">
									<FlexItem>
										<Typography
											variant="div"
											size="lg"
											color={theme.colors.primary.header}
											type="primary"
										>
											{content.totalLabel}
										</Typography>
									</FlexItem>
									<FlexItem>
										<Flex
											direction="row"
											justifyContent="space-between"
											alignItems="baseline"
										>
											<FlexItem>
												<Typography
													variant="div"
													size="lg"
													color={theme.colors.primary.header}
													type="primary"
												>
													USD
												</Typography>
											</FlexItem>
											<FlexItem>
												<Typography variant="h3" type="primary">
													${total.toFixed(2).split(".").shift()}
												</Typography>
											</FlexItem>
											<FlexItem>
												<Typography
													variant="div"
													size="lg"
													color={theme.colors.primary.header}
													type="primary"
												>
													.{total.toFixed(2).split(".").pop()}
												</Typography>
											</FlexItem>
										</Flex>
									</FlexItem>
								</Flex>
							</ContentSection>
						</OrderSummary>
					</Right>
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection type="tertiary">
					<BreadcrumbContainer theme={theme}>
						<Breadcrumb type="tertiary" size="xs" />
					</BreadcrumbContainer>
					<Typography
						variant="h1"
						type="tertiary"
						css={css`
							margin-top: 16px;
							margin-bottom: 50px;
							padding-bottom: 13px;
							@media (max-width: ${theme.breakpoints.lg}) {
								margin-bottom: 22px;
							}
						`}
					>
						{content.title}
					</Typography>
					<Typography
						variant="p"
						type="tertiary"
						css={css`
							margin-bottom: 15px;
							padding-bottom: 13px;
							@media (max-width: ${theme.breakpoints.lg}) {
								margin-bottom: 12px;
							}
						`}
					>
						{content.shipping}
					</Typography>
				</ContentSection>
				<ContentSection>
					<Typography
						variant="p"
						size="xs"
						color={theme.colors.primary.gray}
						italic={true}
					>
						{content.requiredDirections}
					</Typography>
					<Input
						label={content.firstNameLabel}
						value={firstName}
						onChange={(e) => {
							setFirstName(e.target.value);
						}}
						required={true}
						submitted={submitted}
						css={inputStyling}
						language={language}
					/>
					<Input
						label={content.lastNameLabel}
						value={lastName}
						onChange={(e) => {
							setLastName(e.target.value);
						}}
						required={true}
						submitted={submitted}
						css={inputStyling}
						language={language}
					/>
					<Input
						label={content.address1Label}
						value={address1}
						onChange={(e) => {
							setAddress1(e.target.value);
						}}
						required={true}
						submitted={submitted}
						css={inputStyling}
						language={language}
					/>
					<Input
						label={content.address2Label}
						value={address2}
						onChange={(e) => {
							setAddress2(e.target.value);
						}}
						required={false}
						submitted={submitted}
						css={inputStyling}
						language={language}
					/>

					<Input
						label={content.cityLabel}
						value={city}
						onChange={(e) => {
							setCity(e.target.value);
						}}
						required={true}
						submitted={submitted}
						css={inputStyling}
						language={language}
					/>

					<Dropdown
						language={language}
						options={stateOptions}
						label={content.stateLabel}
						value={state}
						submitted={submitted}
						required={true}
						onChange={(val) => {
							setState(val);
						}}
					/>
					<Spacer
						css={css`
							height: 15px;
						`}
					/>
					<Input
						label={content.zipLabel}
						value={zip}
						onChange={(e) => {
							setZip(e.target.value);
						}}
						required={true}
						submitted={submitted}
						css={inputStyling}
						language={language}
					/>

					<EmailInput
						label={content.emailLabel}
						value={email}
						onChange={(e) => {
							setEmail(e.target.value);
						}}
						required={true}
						submitted={submitted}
						css={inputStyling}
						language={language}
					/>
					<PhoneInput
						label={content.phoneLabel}
						value={phoneNumber}
						onChange={(e) => {
							setPhoneNumber(e.target.value);
						}}
						required={true}
						submitted={submitted}
						css={inputStyling}
						language={language}
					/>
					<Flex direction="row" justifyContent="space-between">
						<FlexItem
							css={css`
								width: calc(50% - 5px);
							`}
						>
							<IncrementDecrementInput
								label={content.quantityLabel}
								required={true}
								onIncrement={() => {
									setQuantity(String(Number(quantity) + 1));
								}}
								onDecrement={() => {
									if (Number(quantity) > 1) {
										setQuantity(String(Number(quantity) - 1));
									}
								}}
								onChange={(e) => {
									setQuantity(e.target.value);
								}}
								value={quantity}
								language={language}
							/>
						</FlexItem>
						<FlexItem
							css={css`
								width: 10px;
							`}
						/>
						<FlexItem
							css={css`
								width: calc(50% - 5px);
							`}
						>
							<Typography variant="div" size="md" color={theme.colors.primary.header}>
								{content.totalLabel}
							</Typography>
							<TotalsContainer
								css={css`
									border: 1px solid ${theme.colors.primary.lightGray};
									height: 50px;
									padding: 15px 20px;
								`}
							>
								${total.toFixed(2)}
							</TotalsContainer>
						</FlexItem>
					</Flex>
					<Spacer
						css={css`
							height: 25px;
						`}
					/>
					<Typography variant="h3">{content.orderSummaryLabel}</Typography>
					<Divider
						css={css`
							color: ${theme.colors.primary.darkerGray};
						`}
					/>
					<OrderDetails>
						<Typography variant="div" size="md" color={theme.colors.primary.header}>
							{content.title}
						</Typography>
						<Flex
							direction="row"
							justifyContent="space-between"
							css={css`
								margin-top: 15px;
							`}
						>
							<FlexItem
								css={css`
									width: 50%;
								`}
							>
								<Typography variant="div" size="sm" type="primary">
									{content.quantityLabel}
								</Typography>
							</FlexItem>
							<FlexItem
								css={css`
									width: 50%;
								`}
							>
								<Typography
									variant="div"
									size="sm"
									weight="bold"
									color={theme.colors.primary.header}
									type="primary"
								>
									{quantity} pc(s)
								</Typography>
							</FlexItem>
						</Flex>
						<Flex
							direction="row"
							justifyContent="space-between"
							css={css`
								margin-top: 10px;
							`}
						>
							<FlexItem
								css={css`
									width: 50%;
								`}
							>
								<Typography variant="div" size="sm" type="primary">
									{content.priceLabel}
								</Typography>
							</FlexItem>
							<FlexItem
								css={css`
									width: 50%;
								`}
							>
								<Typography
									variant="div"
									size="sm"
									weight="bold"
									color={theme.colors.primary.header}
									type="primary"
								>
									USD ${total.toFixed(2)}
								</Typography>
							</FlexItem>
						</Flex>
					</OrderDetails>
					<Divider
						css={css`
							color: ${theme.colors.primary.darkerGray};
						`}
					/>
					<Flex direction="row" justifyContent="space-between">
						<FlexItem>
							<Typography
								variant="div"
								size="lg"
								color={theme.colors.primary.header}
								type="primary"
							>
								{content.totalLabel}
							</Typography>
						</FlexItem>
						<FlexItem>
							<Flex
								direction="row"
								justifyContent="space-between"
								alignItems="baseline"
							>
								<FlexItem>
									<Typography
										variant="div"
										size="lg"
										color={theme.colors.primary.header}
										type="primary"
									>
										USD
									</Typography>
								</FlexItem>
								<FlexItem>
									<Typography variant="h3" type="primary">
										${total.toFixed(2).split(".").shift()}
									</Typography>
								</FlexItem>
								<FlexItem>
									<Typography
										variant="div"
										size="lg"
										color={theme.colors.primary.header}
										type="primary"
									>
										.{total.toFixed(2).split(".").pop()}
									</Typography>
								</FlexItem>
							</Flex>
						</FlexItem>
					</Flex>
					<Spacer
						css={css`
							height: 25px;
						`}
					/>
					<Button
						size="md"
						fullwidth={true}
						type="primary"
						label={content.buttonLabel}
						onClick={onFormSubmit}
					/>
					{showAPIMessage && (
						<Messager>
							<MessagerIcon
								css={css`
									background-color: ${apiStatus === "success"
										? theme.colors.secondary.green
										: theme.colors.secondary.red};
								`}
							>
								<Icon
									size="sm"
									color={theme.colors.primary.white}
									icon={apiStatus === "success" ? "check" : "minus"}
								/>
							</MessagerIcon>
							<MessagerText>
								<Typography variant="div" size="md" type="primary">
									{apiMessage}
								</Typography>
							</MessagerText>
						</Messager>
					)}
				</ContentSection>
				<Spacer
					css={css`
						height: 25px;
					`}
				/>
			</Media>
		</>
	);
};

export default StocklistForm;
